import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, HostBinding, Input, NgModule } from '@angular/core';
import { paddingAttribute, colorAttribute } from '@priva/utilities/helpers';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/cdk/observers';
import { ObserversModule } from '@angular/cdk/observers';
import * as i3 from '@priva/utilities/generic';
import { PrivaVisibleNodesPipe } from '@priva/utilities/generic';
import { ReactiveFormsModule } from '@angular/forms';
const _c0 = ["*", [["", "data-label", ""]]];
const _c1 = ["*", "[data-label]"];
const _c2 = (a0, a1) => [a0, a1];
var PrivaCheckboxThemeClass;
(function (PrivaCheckboxThemeClass) {
  PrivaCheckboxThemeClass["onwhite"] = "checkbox--theme-on-white";
  PrivaCheckboxThemeClass["onlightgray"] = "checkbox--theme-on-light-gray";
  PrivaCheckboxThemeClass["onlightblue"] = "checkbox--theme-on-light-blue";
  PrivaCheckboxThemeClass["ondarkblue"] = "checkbox--theme-on-dark-blue";
})(PrivaCheckboxThemeClass || (PrivaCheckboxThemeClass = {}));
class PrivaCheckboxComponent {
  get customColor() {
    return this.theme.toLowerCase() !== 'ondarkblue' ? this.color : null;
  }
  constructor(cdr, host) {
    this.cdr = cdr;
    this.host = host;
    this.theme = 'onwhite';
    this.size = 'md';
    // @deprecated use `padding: 0` instead
    this.hasPadding = true;
    this.PrivaCheckboxThemeClass = PrivaCheckboxThemeClass;
    this.isDisabled = false;
  }
  ngAfterContentInit() {
    this.determineAndSetDisabled();
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
          this.determineAndSetDisabled();
        }
      });
    });
    const elm = this.host.nativeElement.querySelector('input');
    if (elm) {
      this.observer.observe(elm, {
        attributes: true
      });
    }
  }
  determineAndSetDisabled() {
    this.isDisabled = !!this.host.nativeElement.querySelector('input[disabled]');
    this.cdr.markForCheck();
  }
  onContentChange() {
    this.cdr.detectChanges();
  }
  static {
    this.ɵfac = function PrivaCheckboxComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaCheckboxComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaCheckboxComponent,
      selectors: [["priva-checkbox"]],
      hostVars: 2,
      hostBindings: function PrivaCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("--checkbox-color", ctx.customColor);
        }
      },
      inputs: {
        theme: "theme",
        size: "size",
        hasPadding: "hasPadding",
        padding: [2, "padding", "padding", paddingAttribute],
        color: [2, "color", "color", colorAttribute]
      },
      features: [i0.ɵɵInputTransformsFeature],
      ngContentSelectors: _c1,
      decls: 9,
      vars: 18,
      consts: [["optionLabel", ""], [1, "checkbox", 3, "ngClass"], ["data-test", "checkbox-label", 1, "form-control-label"], [1, "input-box"], [1, "option-label", 3, "cdkObserveContent"]],
      template: function PrivaCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵpipe(1, "lowercase");
          i0.ɵɵelementStart(2, "label", 2);
          i0.ɵɵprojection(3);
          i0.ɵɵelement(4, "span", 3);
          i0.ɵɵelementStart(5, "span", 4, 0);
          i0.ɵɵpipe(7, "hasVisibleNodes");
          i0.ɵɵlistener("cdkObserveContent", function PrivaCheckboxComponent_Template_span_cdkObserveContent_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onContentChange());
          });
          i0.ɵɵprojection(8, 1);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          let tmp_4_0;
          const optionLabel_r2 = i0.ɵɵreference(6);
          i0.ɵɵclassProp("checkbox--theme-custom", !!ctx.customColor)("is-disabled", ctx.isDisabled);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(15, _c2, ctx.PrivaCheckboxThemeClass[ctx.theme == null ? null : ctx.theme.toLowerCase()], i0.ɵɵpipeBind1(1, 11, "checkbox--size-" + ctx.size)));
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("padding", (tmp_4_0 = ctx.padding) !== null && tmp_4_0 !== undefined ? tmp_4_0 : null);
          i0.ɵɵclassProp("padding-0", !ctx.hasPadding && !ctx.padding);
          i0.ɵɵadvance(3);
          i0.ɵɵclassProp("sr-only", !i0.ɵɵpipeBind1(7, 13, optionLabel_r2.childNodes));
        }
      },
      dependencies: [i1.NgClass, i2.CdkObserveContent, i1.LowerCasePipe, i3.PrivaVisibleNodesPipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'priva-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n    class=\"checkbox\"\n    [ngClass]=\"[PrivaCheckboxThemeClass[theme?.toLowerCase()], 'checkbox--size-' + size | lowercase]\"\n    [class.checkbox--theme-custom]=\"!!customColor\"\n    [class.is-disabled]=\"isDisabled\"\n>\n    <label\n        data-test=\"checkbox-label\"\n        class=\"form-control-label\"\n        [class.padding-0]=\"!hasPadding && !padding\"\n        [style.padding]=\"padding ?? null\"\n    >\n        <ng-content />\n        <span class=\"input-box\"></span>\n        <span\n            #optionLabel\n            class=\"option-label\"\n            [class.sr-only]=\"!(optionLabel.childNodes | hasVisibleNodes)\"\n            (cdkObserveContent)=\"onContentChange()\"\n        >\n            <ng-content select=\"[data-label]\" />\n        </span>\n    </label>\n</div>\n",
      styles: [":host{display:block}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }], {
    customColor: [{
      type: HostBinding,
      args: ['style.--checkbox-color']
    }],
    theme: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    hasPadding: [{
      type: Input
    }],
    padding: [{
      type: Input,
      args: [{
        transform: paddingAttribute
      }]
    }],
    color: [{
      type: Input,
      args: [{
        transform: colorAttribute
      }]
    }]
  });
})();
class PrivaCheckboxModule {
  static {
    this.ɵfac = function PrivaCheckboxModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaCheckboxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaCheckboxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule, ObserversModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaCheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ReactiveFormsModule, ObserversModule, PrivaVisibleNodesPipe],
      declarations: [PrivaCheckboxComponent],
      exports: [PrivaCheckboxComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaCheckboxComponent, PrivaCheckboxModule };
